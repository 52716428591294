<template>
    <div class="content">
        <!-- 表格内容 -->
        <commonTitle></commonTitle>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                 element-loading-text="拼命加载中"
                 v-loading="loading"
        >
            <el-form-item label="小区名称" prop="villageName">
                <el-input v-model="ruleForm.villageName" placeholder="请输入小区名称"></el-input>
            </el-form-item>
            <el-form-item label="小区编号" prop="villageCard">
                <el-input v-model="ruleForm.villageCard" placeholder="请输入小区编号"></el-input>
            </el-form-item>
            <el-form-item label="物业单位">
                <el-col :span="24">
                    <el-select size="small" v-model="ruleForm.tenementId" placeholder="请选择物业单位" style="width: 100%"
                               filterable clearable>
                        <el-option
                                v-for="item in orgList"
                                :key="item.orgId"
                                :label="item.orgName"
                                :value="item.orgId">
                        </el-option>
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item label="建筑面积" prop="coveredarea">
                <el-input v-model.number="ruleForm.coveredarea" placeholder="请输入建筑面积" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="占地面积" prop="floorspace">
                <el-input type="text" v-model.number="ruleForm.floorspace" placeholder="请输入占地面积"></el-input>
            </el-form-item>
            <el-form-item label="建立时间" prop="settingTime">
                <el-col :span="24">
                    <el-date-picker type="date" placeholder="选择小区建立时间" v-model="ruleForm.settingTime"
                                    value-format="yyyy-MM-dd"
                                    style="width: 100%;"></el-date-picker>
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="small" @click="submitForm('ruleForm')">修改</el-button>
                <el-button @click="resetForm()" size="small">返回</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";

    export default {
        name: "upVillage",
        components: {
            commonTitle
        },
        data() {
            return {
                ruleForm: {
                    villageName: '',
                    villageCard: '',
                    coveredarea: '', //建筑面积
                    settingTime: '',
                    floorspace: '',  //占地面积
                    developersId: '',//开发商Id
                    tenementId: ''//物业id
                },
                rules: {
                    villageName: [
                        {required: true, message: '请输入小区名称', trigger: 'blur'},
                        //  {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
                    ],
                    villageCard: [
                        {required: true, message: '请输入小区编号', trigger: 'change'}
                    ],
                    coveredarea: [
                        {required: true, message: '请输入建筑面积', trigger: 'blur'},
                        {type: 'number', message: '建筑面积必须为数字值'}
                    ],
                    floorspace: [
                        {required: true, message: '请输入占地面积', trigger: 'change'},
                        {type: 'number', message: '占地面积必须为数字值'}
                    ],
                    settingTime: [
                        {required: true, message: '请选择小区建立时间', trigger: 'change'}
                    ]
                },
                url: this.$Config.base_server,
                loading: false,
                orgList: []//机构集合
            };
        }, methods: {
            submitForm(formName) {
                var _this = this;
                _this.loading = true;
                //立即创建按钮
                this.$refs[formName].validate(function (valid) {
                    if (valid) {
                        //向后台提交数据
                        //开发商
                        $.ajax({
                            url: _this.url + "/api-public/opevillage/update?access_token=" + localStorage.getItem("token"),
                            type: 'post',
                            data: JSON.stringify(_this.ruleForm),
                            contentType: 'application/json',
                            success: function (result) {
                                if (result.resp_code == 0) {
                                    _this.loading = false;
                                    //修改成功
                                    _this.$message({
                                        message: result.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'success',
                                        duration: 1000,
                                        onClose: function () {
                                            _this.$router.go(-1);
                                        }
                                    });
                                } else {
                                    //修改失败
                                    _this.loading = false;
                                    _this.$message({
                                        message: result.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'warning'
                                    });
                                }
                            }
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }, resetForm(formName) {
                //返回按钮
                localStorage.removeItem("villageData");
               this.ruleForm.tenementId='';
                this.$router.go(-1);
            }
        }, created() {
            //初始化数据
            var _this = this; //声明一个变量指向Vue实例this，保证作用域一致
            var token = localStorage.getItem("token");
            const data = {
                type: '0'
            };
            $.ajax({
                url: _this.url + "/api-public/opeorg/list?access_token=" + localStorage.getItem("token"),
                data: data,
                contentType: 'application/json',
                success: function (res) {
                    _this.orgList = res.data;
                }
            });
            var tolist = JSON.parse(localStorage.getItem("villageData"));
            console.log(tolist);
            _this.ruleForm = tolist;
            if (tolist.tenementId != '' && tolist.tenementId != null) {
                _this.ruleForm.tenementId = tolist.tenementId;  //物业单位
            }
            _this.ruleForm.floorspace = parseFloat(tolist.floorspace);
            _this.ruleForm.coveredarea = parseFloat(tolist.coveredarea);

        }
    }
</script>

<style scoped>

    .content {
        padding: 20px 111px;
        background: #fff;
    }

    .el-form {
        width: 600px;
        margin: 0 auto;
    }
</style>